import React from "react";
import {GiftsResponse} from "../models/GiftsResponse";
import {Box, Flex, Grid, HStack, Input, InputGroup, InputRightElement, Switch} from "@chakra-ui/react";
import {TraitDropdown} from "../components/TraitDropdown";
import {DeleteIcon} from "@chakra-ui/icons";
import {RankFilterConfig} from "../components/RankFilterConfig";
import {Gift} from "../components/Gift";

const STATE_KEY = 'giftFilterConfig2';

export const GiftList: React.FC<{ data: GiftsResponse }> = (props) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const initialSearchValue = urlSearchParams.get('search') ?? '';
    const savedState = JSON.parse(localStorage.getItem(STATE_KEY) ?? '{}');
    const initialRankConfig = savedState.rankConfig ?? 'all';
    const initialBreedConfig = savedState.breedConfig ?? 'none';
    const initialTribeConfig = savedState.tribeConfig ?? 'none';
    const initialAuspiceConfig = savedState.auspiceConfig ?? 'none';
    const initialTraitFiltersEnabled = savedState.traitFiltersEnabled ?? false;

    const [rankConfig, setRankConfig] = React.useState<number[] | 'all'>(initialRankConfig);
    const [breedConfig, setBreedConfig] = React.useState<string>(initialBreedConfig);
    const [tribeConfig, setTribeConfig] = React.useState<string>(initialTribeConfig);
    const [auspiceConfig, setAuspiceConfig] = React.useState<string>(initialAuspiceConfig);
    const [traitFiltersEnabled, setTraitFiltersEnabled] = React.useState<boolean>(initialTraitFiltersEnabled);
    const [searchValue, setSearchValue] = React.useState<string>(initialSearchValue);
    const [gifts, setGifts] = React.useState<GiftsResponse['gifts']>([]);

    const filterConfigs = [
        ['breeds', breedConfig],
        ['tribes', tribeConfig],
        ['auspices', auspiceConfig],
    ];

    React.useEffect(() => {
        const currentValue = new URLSearchParams(window.location.search).get('search') ?? '';
        if (currentValue === '' && searchValue === '') {
            return;
        }
        const params = new URLSearchParams();
        params.set('search', searchValue);
        window.history.replaceState(null, '', `?${params}`);
    }, [searchValue]);

    React.useEffect(() => {
        const state = {
            rankConfig,
            breedConfig,
            tribeConfig,
            auspiceConfig,
            traitFiltersEnabled,
        };
        localStorage.setItem(STATE_KEY, JSON.stringify(state));
    }, [rankConfig, breedConfig, tribeConfig, auspiceConfig, traitFiltersEnabled]);

    React.useEffect(() => {
        let gifts = props.data.gifts;
        if (traitFiltersEnabled) {
            gifts = gifts.filter(gift => filterConfigs.some(([name, config]) => {
                switch (config) {
                    case 'none':
                        return false;
                    default:
                        return gift[name].includes(config);
                }
            }));
        }
        if (rankConfig !== 'all') {
            gifts = gifts.filter(gift => rankConfig.includes(gift.rank));
        }
        if (searchValue != null) {
            const search = new RegExp(searchValue, 'i');
            gifts = gifts.filter(gift => search.test(gift.name));
        }
        setGifts(gifts);
    }, [rankConfig, breedConfig, tribeConfig, auspiceConfig, traitFiltersEnabled, searchValue]);

    return <>
        <Box>
            <Grid templateColumns="35px repeat(3, 1fr)" gap={6}>
                <Flex direction="column" justify="center" align="center">
                    <Switch
                        colorScheme="teal"
                        isChecked={traitFiltersEnabled}
                        onChange={() => setTraitFiltersEnabled(!traitFiltersEnabled)}
                    />
                </Flex>
                <TraitDropdown
                    name="Breed"
                    key="breed"
                    enabled={traitFiltersEnabled}
                    options={props.data.breeds}
                    selectedOption={breedConfig}
                    onSelectionChange={setBreedConfig}
                />
                <TraitDropdown
                    name="Tribe"
                    key="tribe"
                    enabled={traitFiltersEnabled}
                    options={props.data.tribes}
                    selectedOption={tribeConfig}
                    onSelectionChange={setTribeConfig}
                />
                <TraitDropdown
                    name="Auspice"
                    key="auspice"
                    enabled={traitFiltersEnabled}
                    options={props.data.auspices}
                    selectedOption={auspiceConfig}
                    onSelectionChange={setAuspiceConfig}
                />
            </Grid>
            <HStack spacing={4} align="center" m={3}>
                <InputGroup>
                    <Input
                        placeholder="Search"
                        value={searchValue}
                        onChange={event => setSearchValue(event.target.value)}
                    />
                    <InputRightElement
                        children={<DeleteIcon _hover={{color: 'gray.600', cursor: 'pointer'}} color="gray.300"
                                              onClick={() => setSearchValue('')}/>}
                    />
                </InputGroup>

                <RankFilterConfig enabled={rankConfig} onRankChange={setRankConfig}/>
            </HStack>
        </Box>
        <Box>{gifts.length} Gifts</Box>
        <Box>
            {gifts.map(gift => <Gift gift={gift}/>)}
        </Box>
    </>;
};
import React from 'react';
import {render} from 'react-dom';
import {Loader} from "./hoc/Loader";
import {Loading} from './components/Loading';
import {GiftsResponse} from "./models/GiftsResponse";
import {GiftList} from "./pages/GiftList";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {SingleGift} from "./pages/SingleGift";
import {Box, ChakraProvider} from "@chakra-ui/react";

const App: React.FC<{ data: GiftsResponse }> = (props) => {
    return <ChakraProvider>
        <Box w={1024} m="20px auto">
            <Router>
                <Switch>
                    <Route path="/gift/:giftName">
                        <SingleGift data={props.data}/>
                    </Route>
                    <Route path="/">
                        <GiftList data={props.data}/>
                    </Route>
                </Switch>
            </Router>
        </Box>
    </ChakraProvider>;
};

const AppWithData = Loader({
    promise: fetch('/gifts.json').then(r => r.json()),
    loading: Loading,
    loaded: App,
});

render(<AppWithData/>, document.getElementById('root'));

import React from "react";
import {Box, Text, Link} from "@chakra-ui/react";
import {GiftsResponse} from "../models/GiftsResponse";
import {Link as RouterLink} from "react-router-dom";

interface GiftProps {
    gift: GiftsResponse['gifts'][0];
}

export const Gift: React.FC<GiftProps> = ({ gift }) => {
    return (
        <Box border="1px solid" borderColor="gray.300" borderRadius="5px" m={3} p={3}>
            <Link as={RouterLink} to={`/gift/${gift.name}`} fontSize="x-large" fontWeight="300">{gift.name}</Link>
            <Box fontSize="small" paddingBottom={2} paddingTop={2}>
                <Text>Rank: {gift.rank}</Text>
                {gift.breeds.length > 0 && <Text>Breeds: {gift.breeds.join(", ")}</Text>}
                {gift.auspices.length > 0 && <Text>Auspices: {gift.auspices.join(", ")}</Text>}
                {gift.tribes.length > 0 && <Text>Tribes: {gift.tribes.join(", ")}</Text>}
            </Box>
            <Text paddingBottom={1}>{gift.description}</Text>
            <Text><Text fontWeight="600" as="span">System: </Text>{gift.system}</Text>
        </Box>
    );
};
import React from "react";
import {Button, ButtonGroup, Flex} from "@chakra-ui/react";

export interface RankFilterConfigProps {
    enabled: number[] | 'all';
    onRankChange: (enabled: number[] | 'all') => void;
}

export const RankFilterConfig: React.FC<RankFilterConfigProps> = (props) => {
    const allRanks = Array.from({length: 6}, (_, i) => i + 1);
    const enabled = props.enabled === 'all' ? allRanks : props.enabled;
    const toggleRank = (n: number) => enabled.includes(n) ? enabled.filter(v => v !== n) : [...enabled, n];
    const createRankButton = (n: number) => <Button
        key={n}
        variant={enabled.includes(n) ? "solid" : "outline"}
        onClick={() => props.onRankChange(toggleRank(n))}
        w="50px"
    >
        {n}
    </Button>;
    return <ButtonGroup
        spacing={5}
        border="1px solid #eee"
        borderRadius={10}
        p={2}
        variant="outline"
        colorScheme={"teal"}
    >
        <Flex direction="column" justify="center" align="center">Rank</Flex>
        <Button
            key="none"
            onClick={() => props.onRankChange([])}
            variant={enabled.length === 0 ? 'solid' : 'outline'}
            w="70px"
        >
            None
        </Button>
        <Button
            key="all"
            onClick={() => props.onRankChange('all')}
            variant={enabled.length === 6 ? 'solid' : 'outline'}
            w="70px"
        >
            All
        </Button>
        {allRanks.map(createRankButton)}
    </ButtonGroup>;
};
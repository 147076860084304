import React from "react";

export interface LoaderArgs {
    promise: Promise<any>;
    loading: React.FC;
    loaded: React.FC<{ data: any }>;
}

export const Loader = (args: LoaderArgs): React.FC => {
    const Loading = args.loading;
    const Loaded = args.loaded;
    return () => {
        const [data, setData] = React.useState<any>(null);
        React.useEffect(() => {
            const startTime = Date.now();
            args.promise.then(data => setTimeout(() => setData(data), Math.max(0, 500 - (Date.now() - startTime))));
        }, []);
        if (data != null) {
            return <Loaded data={data}/>;
        }
        return <Loading/>;
    };
};
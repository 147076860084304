import React from "react";
import {HStack, Select, Text} from "@chakra-ui/react";

export interface TraitDropdownProps {
    name: string;
    options: string[];
    selectedOption: string;
    onSelectionChange: (option: string) => void;
    enabled: boolean;
}

export const TraitDropdown: React.FC<TraitDropdownProps> = (props) => {
    return <>
        <HStack spacing={4}>
            <Text color={props.enabled ? 'black' : 'gray.500'}>{props.name}</Text>
            <Select
				placehoder={props.name}
				onChange={(val) => props.onSelectionChange(val.target.value)}
				value={props.selectedOption}
                isDisabled={!props.enabled}
			>
                <option key="none" value="none">none</option>
                {props.options.map(option => <option key={option} value={option}>{option}</option>)}
            </Select>
        </HStack>
    </>;
};
import React from "react";
import {GiftsResponse} from "../models/GiftsResponse";
import {useParams} from "react-router-dom";
import {Gift} from "../components/Gift";

export const SingleGift: React.FC<{ data: GiftsResponse; }> = ({ data}) => {
    const { giftName } = useParams<{ giftName: string; }>();
    const gift = data.gifts.find(g => g.name === giftName);
    if (gift == null) {
        return <h1>not found</h1>;
    }
    return <Gift gift={gift} />;
};
